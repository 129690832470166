@media only screen and (max-width: 600px) {
    .css-1xwt1af-MuiTypography-root.responsive-table-header,
    .css-1ygcj2i-MuiTableCell-root.responsive-table-cell {
        font-size: 0.75rem;
    }

    /* New responsive styles for the form controls and slider */
    .impact-metrics-formControl {
        width: 100%;
        margin: 8px 0; /* Adjust top and bottom margins, remove left and right margins */
    }

    .impact-metrics-slider {
        width: 100%;
        margin: 16px 0; /* Increased margin for visual separation */
    }

    .impact-metrics-slider .MuiSlider-thumb {
        height: 24px;
        width: 24px;
    }

    .impact-metrics-slider .MuiSlider-valueLabel {
        font-size: 0.875rem;
    }
}

.impact-metrics-title {
    font-weight: bold !important; /* Apply bold font weight */
}

/* Responsive styles for smartphone screens */
@media only screen and (max-width: 600px) {
    .Container .impact-metrics-title {
        font-size: 1.25rem; /* Smaller font size for small screens */
        font-weight: bold; /* Ensure the title remains bold */
    }
}

.impact-metrics-break {
    display: block;
}

.language-link {
    color: inherit;
    transition: background-color 0.3s ease;
    padding: 4px 8px;
    border-radius: 4px;
}

.language-link:hover {
    color: #8685EF;
}

.left-column {
    background-color: transparent;
    transition: background-color 0.2s ease;
}

.MuiTableRow-root:hover .left-column {
    background-color: rgba(0, 0, 0, 0.04);
}