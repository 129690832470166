.gpm-explanation {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.gpm-explanation h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #38292C;
    margin-bottom: 1rem;
}

.gpm-explanation h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #38292C;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.gpm-explanation h3 {
    font-size: 1.25rem;
    font-weight: bold;
    color: #38292C;
    margin-top: 1rem;
}

.gpm-explanation p {
    margin-bottom: 1rem;
    color: #38292C;
}

.gpm-explanation .divider {
    margin: 1.5rem 0;
    color: #ABABAB;
}
