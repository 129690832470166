.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 999; /* Ensure it's below the sidebar but above everything else */
}

.hamburger-icon {
    font-size: 24px; /* Adjust the default size as needed */
}

/* Larger hamburger menu on PC view */
@media (min-width: 768px) {
    .hamburger-icon {
        font-size: 36px; /* Larger size for PC */
    }
}
